<template>
    <div class="main-wrapper">
        <div class="top-content" v-if="this.$route.query.method === 'stuTrain' ||this.$route.query.method === 'teaTrain'||this.$route.query.method === 'teaExam'">
            <!-- <el-breadcrumb separator=">" class="genera-breadcrumb">
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>网上交易管理</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                <el-breadcrumb-item>{{breadcrumbTitle}}</el-breadcrumb-item>
                <el-breadcrumb-item>查看详情</el-breadcrumb-item>
            </el-breadcrumb> -->
            <div class="btn-back">
                <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="answer">
            <p class="answer-title">学生提交答案</p>
            <div v-if="this.$route.query.module==='发货地址管理'">
                <el-table :data="deliverAddress"  class="customTable goods-text-table" border style="width: 100%; flex: 1;">
                    <el-table-column prop="where_address" label="所在地址" width="200px"></el-table-column>
                    <el-table-column prop="detailed_address" label="详细地址" min-width="200px"></el-table-column>
                    <el-table-column prop="postcode"  label="邮编" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
                    <el-table-column prop="ship_name"  label="发货人姓名" align="center"></el-table-column>
                    <el-table-column prop="defualt" label="默认" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.defualt===1?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <div v-if="this.$route.query.module==='备件库退货地址管理'">
                <div class="content">
                    <div class="item">
                        <span class="span-title">联系人：</span>
                        <span>{{stu_answer.ship_name}}</span>
                    </div>
                    <div class="item">
                        <span class="span-title">联系电话：</span>
                        <span>{{stu_answer.phone}}</span>
                    </div>
                    <div class="item">
                        <span class="span-title">邮编：</span>
                        <span>{{stu_answer.postcode}}</span>
                    </div>
                    <div class="item">
                        <span class="span-title">退货地址：</span>
                        <span>{{stu_answer.where_address}}&nbsp&nbsp&nbsp{{stu_answer.detailed_address}}</span>
                    </div>
                </div>
            </div>
            <div v-if="this.$route.query.module==='自主售后退货地址管理'">
                <el-table :data="afterSalesReturnAddres"  class="customTable goods-text-table" style="width: 100%; flex: 1;">
                    <el-table-column prop="ship_name"  label="联系人" align="center"></el-table-column>
                    <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
                    <el-table-column prop="postcode"  label="邮编" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="where_address" label="地址" width="400px" align="center"></el-table-column>
<!--                    <el-table-column prop="detailed_address" label="详细地址" width="200px"></el-table-column>-->
                    <el-table-column prop="defualt" label="默认" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.defualt===1?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <div v-if="this.$route.query.module==='运费模板'" class="freight-detail">
                <div class="freight-detail-item">
                    <div class="freight-title">
                        <span>{{freight.title}}</span>
                    </div>
                </div>
                <el-table :data="freight.freightList" style="width: 100%; flex: 1" size="medium"
                          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                          :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
                    <el-table-column label="运送方式" prop="delivery_mode">
                        <template>
                            <span>{{ freight.delivery_mode }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="运送到（国内）" align="center" width="320">
                        <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.city:'为划分的区域将归于该模板下的默认运费'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="首件（个）" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.first_thing:freight.defaultNum}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="首费（元）" align="center">
                        <template slot-scope="scope">
                            <span> {{scope.row.city?scope.row.first_cost:freight.defaultPrice}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="续件（个）" align="center">
                       <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.add_think:freight.addNum}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="续费（元）" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.add_cost:freight.addPrice}} </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="answer">
            <p class="answer-title">得分答案</p>
            <div v-if="this.$route.query.module==='发货地址管理'">
                <el-table :data="teaDeliverAddress"  class="customTable goods-text-table" border style="width: 100%; flex: 1;">
                    <el-table-column prop="where_address" label="所在地址" width="200px"></el-table-column>
                    <el-table-column prop="detailed_address" label="详细地址" min-width="200px"></el-table-column>
                    <el-table-column prop="postcode"  label="邮编" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
                    <el-table-column prop="ship_name"  label="发货人姓名" align="center"></el-table-column>
                    <el-table-column prop="defualt" label="默认" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.defualt===1?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <div v-if="this.$route.query.module==='备件库退货地址管理'">
                <div class="content">
                    <div class="item">
                        <span class="span-title">联系人：</span>
                        <span>{{right_answer.ship_name}}</span>
                    </div>
                    <div class="item">
                        <span class="span-title">联系电话：</span>
                        <span>{{right_answer.phone}}</span>
                    </div>
                    <div class="item">
                        <span class="span-title">邮编：</span>
                        <span>{{right_answer.postcode}}</span>
                    </div>
                    <div class="item">
                        <span class="span-title">退货地址：</span>
                        <span>{{right_answer.where_address}}&nbsp&nbsp&nbsp{{right_answer.detailed_address}}</span>
                    </div>
                </div>
            </div>
            <div v-if="this.$route.query.module==='自主售后退货地址管理'">
                <el-table :data="teaAfterSalesReturnAddres"  class="customTable goods-text-table" style="width: 100%; flex: 1;">
                    <el-table-column prop="ship_name"  label="联系人" align="center"></el-table-column>
                    <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
                    <el-table-column prop="postcode"  label="邮编" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="where_address" label="地址" width="400px" align="center"></el-table-column>
                    <!--                    <el-table-column prop="detailed_address" label="详细地址" width="200px"></el-table-column>-->
                    <el-table-column prop="defualt" label="默认" align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.defualt===1?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <div v-if="this.$route.query.module==='运费模板'" class="freight-detail">
                <div class="freight-detail-item">
                    <div class="freight-title">
                        <span>{{teaFreight.title}}</span>
                    </div>
                </div>
                <el-table :data="teaFreight.freightList" style="width: 100%; flex: 1" size="medium"
                          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                          :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
                    <el-table-column label="运送方式" prop="delivery_mode">
                        <template>
                            <span>{{ teaFreight.delivery_mode }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="运送到（国内）" align="center" width="320">
                        <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.city:'为划分的区域将归于该模板下的默认运费'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="首件（个）" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.first_thing:teaFreight.defaultNum}}     </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="首费（元）" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.first_cost:teaFreight.defaultPrice}}  </span>
                        </template>
                    </el-table-column>
                     <el-table-column label="续件（个）" align="center">
                       <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.add_think:teaFreight.addNum}}</span>
                        </template>
                    </el-table-column>
                     <el-table-column label="续费（元）" align="center">
                       <template slot-scope="scope">
                            <span>{{scope.row.city?scope.row.add_cost:teaFreight.addPrice}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    import {stuOpTrainData,tranDistributionTeaExamDetail} from '@/utils/apis'
    export default {
        name: "DistributionDetail",
        data(){
            return{
                afterSalesReturnAddres: [],//自主售后退货地址管理
                deliverAddress: [],//发货地址管理
                teaDeliverAddress:[],//得分答案
                freightTemplate: [],//运费模板
                salesReturnAddress: [],//备件库退货地址管理
                stu_answer:{
                    ship_name:'',
                    phone:'',
                    postcode:'',
                    where_address:'',
                    detailed_address:'',
                },
                right_answer:{
                    ship_name:'',
                    phone:'',
                    postcode:'',
                    where_address:'',
                    detailed_address:'',
                },
                teaAfterSalesReturnAddres:[],
                totalScore:0,
                stu_score:0,
                // 运费单模板
                freight: {
                    title: '',
                    defaultPrice: 0,
                    defaultNum: 0,
                    addNum: 0,
                    addPrice: 0,
                    exemptPostage: 0,
                    freightList: [],
                    delivery_mode: '',
                },
                teaFreight: {
                    title: '',
                    defaultPrice: 0,
                    defaultNum: 0,
                    addNum: 0,
                    addPrice: 0,
                    exemptPostage: 0,
                    freightList: [],
                    delivery_mode: '',
                },
                freightTemplateTitle:'',//运送模板
                breadcrumbTitle:'',//运送模板
            }
        },
        mounted() {
            this.getStuOpTrainDataDetail()
            this.getBreadcrumb()
        },
        methods:{
            //获取详情
            async getStuOpTrainDataDetail(){
                let params = {}
                let res
                if(this.$route.query.method === 'stuTrain'||this.$route.query.method === 'teaTrain') { //学生训练 教师训练
                    params.train_id = this.$route.query.trainId;
                    res = await stuOpTrainData(params)
                    //发货地址
                    if (res.data.deliver_address.stu_answer.length>0){
                        this.deliverAddress = res.data.deliver_address.stu_answer
                    }else {
                        this.deliverAddress =[]
                    }
                    if (res.data.deliver_address.right_answer.length>0){
                        this.teaDeliverAddress = res.data.deliver_address.right_answer
                    }else {
                        this.teaDeliverAddres =[]
                    }
                    //备件库退货地址管理
                    if (res.data.sales_return_address.stu_answer){
                        this.stu_answer=res.data.sales_return_address.stu_answer
                    }
                    if (res.data.sales_return_address.right_answer){
                        this.right_answer=res.data.sales_return_address.right_answer
                    }
                    //自主售后退货地址管理
                    if(res.data.after_sales_return_address.stu_answer.length>0){
                        this.afterSalesReturnAddres=res.data.after_sales_return_address.stu_answer
                    }else {
                        this.afterSalesReturnAddres=[]
                    }
                    if(res.data.after_sales_return_address.right_answer.length>0){
                        this.teaAfterSalesReturnAddres=res.data.after_sales_return_address.right_answer
                    }else {
                        this.teaAfterSalesReturnAddres=[]
                    }
                    //运费模板
                    if (res.data.freight_template.stu_answer){
                        this.freight.title = res.data.freight_template.stu_answer.freight_template_title;
                        this.freight.defaultPrice = res.data.freight_template.stu_answer.default_price;
                        this.freight.defaultNum = res.data.freight_template.stu_answer.default_num;
                        this.freight.addPrice = res.data.freight_template.stu_answer.add_price;
                        this.freight.addNum = res.data.freight_template.stu_answer.add_num;
                        this.freight.freightList = res.data.freight_template.stu_answer.freight_setting;
                        this.freight.exemptPostage = res.data.freight_template.stu_answer.is_exempt_postage
                    }
                    if (res.data.freight_template.right_answer){
                        this.teaFreight.title = res.data.freight_template.right_answer.freight_template_title;
                        this.teaFreight.defaultPrice = res.data.freight_template.right_answer.default_price;
                        this.teaFreight.defaultNum = res.data.freight_template.right_answer.default_num;
                        this.teaFreight.addPrice = res.data.freight_template.right_answer.add_price;
                        this.teaFreight.addNum = res.data.freight_template.right_answer.add_num;
                        this.teaFreight.freightList = res.data.freight_template.right_answer.freight_setting;
                        this.teaFreight.exemptPostage = res.data.freight_template.right_answer.is_exempt_postage
                    }
                    if (res.data.freight_template.stu_answer.is_exempt_postage === 1) {
                        //商家包邮
                        this.freight.freightList = [{
                            add_cost: res.data.freight_template.stu_answer.default_price,
                            add_think: res.data.freight_template.stu_answer.default_num,
                            city: "全国",
                            delivery_mode: "快递",
                            first_cost: res.data.freight_template.stu_answer.default_price,
                            first_thing: res.data.freight_template.stu_answer.default_num,
                        }]
                    } else {
                        if (res.data.freight_template.stu_answer.freight_setting.length === 0) {
                            this.freight.freightList = [{
                                add_cost: res.data.freight_template.stu_answer.default_price,
                                add_think: res.data.freight_template.stu_answer.default_num,
                                city: "全国",
                                delivery_mode: "快递",
                                first_cost: res.data.freight_template.stu_answer.default_price,
                                first_thing: res.data.freight_template.stu_answer.default_num,
                            }]
                        }
                    }
                    if (res.data.freight_template.right_answer.is_exempt_postage === 1) {
                        //商家包邮
                        this.teaFreight.freightList = [{
                            add_cost: res.data.freight_template.right_answer.default_price,
                            add_think: res.data.freight_template.right_answer.default_num,
                            city: "全国",
                            delivery_mode: "快递",
                            first_cost: res.data.freight_template.right_answer.default_price,
                            first_thing: res.data.freight_template.right_answer.default_num,
                        }]
                    } else {
                        if (res.data.freight_template.right_answer.freight_setting.length === 0) {
                            this.teaFreight.freightList = [{
                                add_cost: res.data.freight_template.right_answer.default_price,
                                add_think: res.data.freight_template.right_answer.default_num,
                                city: "全国",
                                delivery_mode: "快递",
                                first_cost: res.data.freight_template.right_answer.default_price,
                                first_thing: res.data.freight_template.right_answer.default_num,
                            }]
                        }
                    }
                }else {//考试
                    params.user_id = this.$route.query.id // 学生id
                    params.exam_id = this.$route.query.examId
                    res = await tranDistributionTeaExamDetail(params)
                    //发货地址
                    if (res.data.deliver_address.stu_answer.length>0){
                        this.deliverAddress = res.data.deliver_address.stu_answer
                    }else {
                        this.deliverAddress =[]
                    }
                    if (res.data.deliver_address.right_answer.length>0){
                        this.teaDeliverAddress = res.data.deliver_address.right_answer
                    }else {
                        this.teaDeliverAddres =[]
                    }
                    //备件库退货地址管理
                    if (res.data.sales_return_address.stu_answer){
                        this.stu_answer=res.data.sales_return_address.stu_answer
                    }
                    if (res.data.sales_return_address.right_answer){
                        this.right_answer=res.data.sales_return_address.right_answer
                    }
                    //自主售后退货地址管理
                    if(res.data.after_sales_return_address.stu_answer.length>0){
                        this.afterSalesReturnAddres=res.data.after_sales_return_address.stu_answer
                    }else {
                        this.afterSalesReturnAddres=[]
                    }
                    if(res.data.after_sales_return_address.right_answer.length>0){
                        this.teaAfterSalesReturnAddres=res.data.after_sales_return_address.right_answer
                    }else {
                        this.teaAfterSalesReturnAddres=[]
                    }
                    //运费模板
                    if (res.data.freight_template.stu_answer){
                        this.freight.title = res.data.freight_template.stu_answer.freight_template_title;
                        this.freight.defaultPrice = res.data.freight_template.stu_answer.default_price;
                        this.freight.defaultNum = res.data.freight_template.stu_answer.default_num;
                        this.freight.addPrice = res.data.freight_template.stu_answer.add_price;
                        this.freight.addNum = res.data.freight_template.stu_answer.add_num;
                        this.freight.freightList = res.data.freight_template.stu_answer.freight_setting;
                        this.freight.exemptPostage = res.data.freight_template.stu_answer.is_exempt_postage;
                        this.freight.delivery_mode = res.data.freight_template.stu_answer.delivery_mode;
                    }
                    if (res.data.freight_template.right_answer){
                        this.teaFreight.title = res.data.freight_template.right_answer.freight_template_title;
                        this.teaFreight.defaultPrice = res.data.freight_template.right_answer.default_price;
                        this.teaFreight.defaultNum = res.data.freight_template.right_answer.default_num;
                        this.teaFreight.addPrice = res.data.freight_template.right_answer.add_price;
                        this.teaFreight.addNum = res.data.freight_template.right_answer.add_num;
                        this.teaFreight.freightList = res.data.freight_template.right_answer.freight_setting;
                        this.teaFreight.exemptPostage = res.data.freight_template.right_answer.is_exempt_postage;
                        this.teaFreight.delivery_mode = res.data.freight_template.right_answer.delivery_mode;
                    }
                    if (res.data.freight_template.stu_answer.is_exempt_postage === 1) {
                        //商家包邮
                        this.freight.freightList = [{
                            add_cost: res.data.freight_template.stu_answer.default_price,
                            add_think: res.data.freight_template.stu_answer.default_num,
                            city: "全国",
                            delivery_mode: "快递",
                            first_cost: res.data.freight_template.stu_answer.default_price,
                            first_thing: res.data.freight_template.stu_answer.default_num,
                        }]
                    } else {
                        if (res.data.freight_template.stu_answer.freight_setting) {
                            if (res.data.freight_template.stu_answer.freight_setting.length == 0) {
                                this.freight.freightList = [{
                                    add_cost: res.data.freight_template.stu_answer.default_price,
                                    add_think: res.data.freight_template.stu_answer.default_num,
                                    city: "全国",
                                    delivery_mode: "快递",
                                    first_cost: res.data.freight_template.stu_answer.default_price,
                                    first_thing: res.data.freight_template.stu_answer.default_num,
                                }]
                            }
                        }
                    }
                    if (res.data.freight_template.right_answer.is_exempt_postage === 1) {
                        //商家包邮
                        this.teaFreight.freightList = [{
                            add_cost: res.data.freight_template.right_answer.default_price,
                            add_think: res.data.freight_template.right_answer.default_num,
                            city: "全国",
                            delivery_mode: "快递",
                            first_cost: res.data.freight_template.right_answer.default_price,
                            first_thing: res.data.freight_template.right_answer.default_num,
                        }]
                    } else {
                        if (res.data.freight_template.right_answer.freight_setting == undefined) {
                            this.teaFreight.freightList = [{
                                add_cost: res.data.freight_template.right_answer.default_price,
                                add_think: res.data.freight_template.right_answer.default_num,
                                city: "全国",
                                delivery_mode: "快递",
                                first_cost: res.data.freight_template.right_answer.default_price,
                                first_thing: res.data.freight_template.right_answer.default_num,
                            }]
                        }
                    }
                }
            },
            getBreadcrumb(){
                if (this.$route.query.module==='发货地址管理'){
                    this.breadcrumbTitle='发货地址管理'
                }else if(this.$route.query.module==='备件库退货地址管理'){
                    this.breadcrumbTitle='备件库退货地址管理'
                }else if(this.$route.query.module==='自主售后退货地址管理'){
                    this.breadcrumbTitle='自主售后退货地址管理'
                }else {
                    this.breadcrumbTitle='运费模板'
                }
            },

            goBack(){
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper{
        padding: 0 20px;
        .top-content{
            position: relative;
            .btn-back{
                position: absolute;
                // top: -12px;
                right: 20px;
            }
        }
        .answer{
            .answer-title{
                padding:20px 0;
                margin: 0;
                font-size:24px;
            }
        }
        .content{
            border: 1px solid #EBEEF5;
            border-bottom: none;
            height: 100%;
            .item{
                padding:20px 20px;
                border-bottom: 1px solid #EBEEF5;
                .span-title{
                    display: inline-block;
                    text-align: right;
                    width:70px;
                }
            }
        }
        .freight-detail{
            .freight-title {
                font-size: 20px;
                padding-left: 15px;
                line-height: 1;
                position: relative;
                display: flex;
                align-items: center;
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 5px;
                    height: 20px;
                    background: #2DC079;
                    border-radius: 3px;
                    transform: translateY(-50%);
                }
            }
            .freight-detail-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                .btn-back{
                    margin-top: -5px;
                }
            }
        }
        ::v-deep .el-table__body-wrapper{
            max-height: 630px;
            overflow-y: auto;
        }
    }
</style>